import React, { useState, useEffect} from 'react';
import { color, motion } from 'framer-motion';
import './modals.scss' 
import { images } from "../../constants"



const project_dqn = () => {
    
    return (
    
          <div className="modal_main">

            <div className="modal_main-images dqn">

              <div className="modal_main-images-image dqn1">

                <img src={images.lunar1} />
                {/* <h2 className='bold-text' style={{marginTop: 10 }}> 100 Training Episodes</h2> */}
                <p> 100 Training Episodes</p>

              </div>

              <div className="modal_main-images-image dqn">

                <img src={images.lunar2} />
                <p> 200 Training Episodes</p>

              </div>

              <div className="modal_main-images-image dqn">

                <img src={images.lunar3} />
                <p> 1500 Training Episodes</p>

              </div>

            </div>

            <div className="modal_main-description">
              <p>This project evaluated the performance of two popular optimization algorithms, Deep Q-Learning and Genetic Algorithms, on training an agent's policy network in both dense and sparse reward structures. The experiments were conducted on two classic reinforcement learning environments, namely the Lunar Lander and Mountain Car scenarios.</p>
              <br></br>
              <p>The main takeaway is that Deep Q-Learning is quite fast and effective at optimizing policies in dense reward environments, but struggles with sparse ones. The Genetic Algorithm however was able to successfully train the networks in the sparse environments albeit at the cost of a much larger number of training episodes.</p>
            </div>

          </div>
    )
}

const project_genre = () => {
    
  return (
    
    <div className="modal_main">

      <div className="modal_main-images">

        <div className="modal_main-images-image genre">

          <img src={images.genreHeatmap} />
          <p> Confusion Matrix for GRU</p>

        </div>

        <div className="modal_main-images-image genre">

          <img src={images.lyrics} />
          <p> Sample of genre lyrics generated by the LSTM model</p>

        </div>


      </div>

      <div className="modal_main-description">
      <p>This works examines the effectiveness of various RNN-based language modeling architectures for music lyrics classification and generation. I created a dataset  specifically for this study that consists of 80,000 song lyrics from the Rock, Pop, Rap, and Country genres.</p>
      <br></br>
      <p> The goal of this project was to assess the classification performance of three main architectures: Vanilla RNN, GRU, and several LSTM variations, including multilayer and bidirectionality. The best-performing classifier was the GRU, achieving a macro-F1 score of 0.71 and a validation accuracy of 72%. Additionally, these architectures were trained on the task of lyrics generation and it seemed like LSTM consistently generated lyrics of higher quality compared to the other models.</p>
      <br></br>
      <p> Moreover, additional experiments were conducted to explore how the initial cell-state and hidden-state can be utilized to guide the LSTM-based model in generating lyrics specific to a particular genre.</p>
      </div>

    </div>
)
}


const project_facemask = () => {
    
  return (
    
    <div className="modal_main">

      <div className="modal_main-images">

        <div className="modal_main-images-image mask">

          <img src={images.facemask_gif1} />
          <p> Realtime facemask detection with YOLOv5</p>

        </div>

        <div className="modal_main-images-image mask">

          <img src={images.facemask2} />
          <p> Facemask detection of various angles/styles </p>

        </div>


      </div>

      <div className="modal_main-description">
      <p>The implementation of automated face mask detection would facilitate the enforcement of mask protocols by communities and organizations. This project focused on the development of four distinct face mask detection algorithms, utilizing diverse datasets, including large synthetic datasets featuring digitally superimposed masks on faces, as well as smaller realistic datasets showcasing masks in different positions and locations.</p>
      <br></br>
      <p>The initial approach involved training a CNN from scratch, utilizing the MediaPipe face-detection model, resulting in an accuracy of 85%. The second approach employed fine-tuning of pre-trained CNNs, specifically GoogLeNet and InceptionV3, achieving an impressive accuracy of 97% on carefully curated data of cropped faces. For the third approach, two-stage pre-trained models, R-CNN and VGG-19, were utilized, resulting in an accuracy of nearly 95% on real-life images. Finally, the fourth approach employed the state-of-the-art computer vision model, YOLOv5, which achieved a .96 mAP on various real-life images, as well as real-time video feeds.</p>
      <br></br>
      <p> The main takeaways from this work is that models trained on combined datasets outperformed those trained on a single dataset when evaluated with real-life data and that transfer learning approaches significantly outperformed CNNs built/trained from scratch.</p>
      </div>

    </div>
)
}

const project_medpredictr = () => {
    
  return (
    
    <div className="modal_main">

      <div className="modal_main-images">

        <div className="modal_main-images-image mpr">

          <img src={images.medpredictr_ui1} />
          <p> Misclassification rates by MRI image types</p>

        </div>

        <div className="modal_main-images-image mpr">

          <img src={images.medpredictr_ui2} />
          <p> Detected tumors using YOLOv5</p>

        </div>


      </div>

      <div className="modal_main-description">
      <p> For many years, polling has shown that high health care costs are a burden
                             on U.S. families and rank as one of their top financial concerns. Moreover, 
                             there are currently very few publically available tools and data sources that
                             would assist families in learning more about these costs and ways of reducing them.</p>
      <br></br>

      <p> MedPredictR is a Shiny application made with 
          the primary purpose of predicting annual medical expenses for uninsured individuals and families.
                             "It utilizes an ensemble of state of the art regression models in order to maximize
                             the probability of providing an accurate estimation of costs. The data is 
                             collected from a variety of sources including from users theselves that wish to
                             contribute in a crowdsourcing effort. This app also provides users with the
                             tools to explore and visualize the underling data and models themselves.</p>
      </div>

    </div>
)
}

const project_tumor = () => {
    
  return (
    
    <div className="modal_main">

      <div className="modal_main-images">

        <div className="modal_main-images-image tumor">

          <img src={images.tumorDatasets} />
          <p> Misclassification rates by MRI image types</p>

        </div>

        <div className="modal_main-images-image tumor">

          <img src={images.tumors} />
          <p> Detected tumors using YOLOv5</p>

        </div>

        <div className="modal_main-images-image tumor">

          <img src={images.tumor_ui} />
          <p> UI for custom Streamlit classifier webapp  </p>

        </div>


      </div>

      <div className="modal_main-description">
      <p> Brain tumors comprise 2% of all cancers, but are disproportionately responsible for cancer-related deaths. Thus, early and accurate detection of brain tumors is imperative for maximizing patient survivability.</p>
      <br></br>
      <p> This project compared the accuracy of a custom CNN built from scratch and trained on augmented images to a EfficientNet B4 model pre-trained on the ImageNet dataset. Though both methods worked quite well, the transfer learning approach was much faster with a sensitivity of over 0.9.  </p>
      <br></br>
      <p> I also constructed a detection framework using YOLOv5 that can report the location of the tumors as well as a custom Streamlit webapp that can classify MRI images uploaded by users.</p>
      </div>

    </div>
)
}

const project_tsp = () => {
    
  return (
    
    <div className="modal_main">

      <div className="modal_main-images">

        <div className="modal_main-images-image tsp">

          <img src={images.tsp_results1} />
          <p> Path length results per generation (GA)</p>

        </div>

        <div className="modal_main-images-image tsp">

          <img src={images.tsp_results2} />
          <p> Optimal path found for 30 points (GA)  </p>

        </div>


      </div>

      <div className="modal_main-description">
      <p> I created a a pure python/numpy/pandas package implementing two gradient-free optimization algorithms, the Genetic Algorithm (GA) and Particle Swarm Optimization (PSO), to solve a version of the Traveling Salesman Problem (TSP) This is a well-known NP-hard problem in computer science and has applications in a myriad of fields including transportation, electronics and genetics. The package was written in a highly modular fashion to allow users to adapt it to handle similar problems such as the vehicle routing problem or to intake non-synthetic, real-world data.</p>
      <br></br>
      <p>One major advantage of gradient-free optimization techniques like the GA and PSO to solve a problem like TSP is that they can identify many permutations of cities that are close to the ideal solution while converging on to the global optimal solution. These close-to-optimal solutions can be used as starting points for further refinement or as alternative solutions in situations when the ideal solution is not needed or is too costly.</p>
      <br></br>
      <p> The main takeaway from this work is that genetic algorithms seem to have better optimization results at the cost of efficiency.</p>
      </div>

    </div>
)
}

const projects = [

    { title: 'Deep Q-Learning in OpenAI Gym', imgUrl: images.dqn, 
    summary: "Comparison of Deep Q-Learning vs Genetic Algorithm in sparse reward environments.",
    description: project_dqn, github: "https://github.com/theturbokhemist/DeepQLearning-with-Sparse-Rewards"  },
    { title: 'Genre Classification with RNNs', imgUrl: images.genreHeatmap, 
    summary: "Classifying and generating music lyrics using different RNN architectures.",
    description: project_genre, github: "https://github.com/theturbokhemist/Lyric-Genre-Classification-and-Generation" },
    { title: 'Facemask Detection with YOLOv5', imgUrl: images.facemask, 
    summary: "Comparing various object detection algorithms for realtime facemask detection. ",
    description: project_facemask, github: "https://github.com/theturbokhemist/FaceMaskDetection" },
    { title: 'MedPredictR', imgUrl: images.medpredictr, 
    summary: "RShiny App that helps predict medical expenses for uninsured families.",
    description: project_medpredictr, github: "https://github.com/theturbokhemist/MedPredictR" },
    { title: 'Brain Tumor Classification', imgUrl: images.tumors, 
    summary: "Detecting and classifying brain tumors with varying deep learning approaches.",
    description: project_tumor, github: "https://github.com/theturbokhemist/BrainTumorClassificationDL" },
    { title: 'Traveling Salesman Optimization', imgUrl: images.tsp_pso, 
    summary: "Particle Swarm Optimization vs Genetic Algorithm in solving the Traveling Salesman Problem. ",
    description: project_tsp, github: "https://github.com/theturbokhemist/Optimization-Algorithms-for-Traveling-Salesman-Problem" }
  
  ];

  export default projects;